<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.menu-bar
  height: 40px
.frame
  width: calc(100vw - 300px)
  height: calc(100vh - 40px)
  overflow: scroll
.table-project
  width: 3000px
  border-color: #d4d4d4
  font-size: 14px
  border-width: 1px 0px 1px 0px
  th, .th
    padding: 0.5rem
    &.header
      color: #999
      font-weight: normal
      &:hover
        background-color: #f0f0f0
        cursor: pointer
  .th-check
    width: 40px
    padding-top: 7px
    padding-left: 5px
    text-align: center
  .td-check
    width: 40px
    padding: 7px 0px 7px 6px
    text-align: center
  tbody
    tr
      td
        padding: 0.5rem

        &.hover
          // cursor: pointer
          .hover-visible
            visibility: hidden
          &:hover
            background-color: #fafafa
            // background: rgb(2,0,36);
            background: linear-gradient(180deg, #fefefe 0%, #fafafa 100%)
            box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.5)
            .hover-visible
              visibility: visible

        &.row-selected
          background-color: mix(#007bff, #f0f0f0, 8%)

          td
            border-top-color: mix(#007bff, #f0f0f0, 20%)

      .td
        overflow: hidden
        span
          width: inherit
          // display: block
          overflow: hidden
          word-break: break-word
          // white-space: nowrap
.col-100
  max-width: 100px
  display: inline-flex
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
.col-150
  max-width: 150px
  display: inline-flex
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
.card-project-record
  background-color: #fff
  font-size: 14px
  &:hover
    // background-color: rgba(darken(#f8f9fa, 50%), .03)
    border: solid 1px #777 !important
    cursor: pointer
.th-group
  // text-align: center
  // background-color: mix(#f1c40f, #f8f9fa, 20%)
  font-weight: 600 !important
  // background-color: #f8f9fa
  // z-index: 10
  // left: 0px
.td-group
  // text-align: center
  color: #555
  // color: #999
  font-weight: 600 !important
  // background-color: #f8f9fa
  // background-color: rgba(darken(#f8f9fa, 50%), .03)
  // z-index: 10
  // left: 0px
  // color: mix(#f1c40f, #555, 50%)
.note
  font-size: 12px
  white-space: pre-line
  word-break: break-word
  font-weight: 600
.today
  color: tomato
  float: right
  font-size: 11px
  // line-height: 20px
  font-weight: bold
.day-sun
  .dow
    color: tomato !important
.day-sat
  .dow
    color: royalblue !important
</style>
<template lang="pug">
div.async(:class='{done: done}')
  .menu-bar.d-flex
    .p-2
      button.btn.btn-default(@click.stop='' type='button') 달력
      b-spinner.ml-2.async(label='가져오는 중...' small variant='secondary' :class='{done:loading}')

  .frame
    div.pt-3
      div.d-flex.table-project.border-top.position-sticky.bg-white.border-bottom(style='width: max-content; top:0px; z-index: 10; margin-bottom: -1px')

        div.border-left.header.handle.th.text-center(style='width: 200px' v-for='date in dates' :class='[dates_dow[date]]')
          strong.dow.text-dark.float-left {{ date | localdate_dowonly }}
          | {{ date | localdate_dateonly }}
          span.today(v-if='today == date') ⬤
          //- span.today(v-if='today == date') 오늘
        div.th.bg-white(style='width: 200px; margin: -1px; 0px;')

      table.table.table-project.border-bottom.mb-0(v-if='cols_ready' style='width: max-content')
        tbody
          tr
            td.td.border-left.hover(style='width: 200px; height: 90vh' v-for='date in dates')
              .p-1.shadow-sm.border.rounded.card-project-record.mb-1(v-for='row of date_indexed[date]'
                @click.exact='open_record(row)'
              )
                template(v-if='row.type == "record"')
                  //- span.d-block {{row}}
                  //- span.d-block {{row.field_text}}
                  template(v-if='$store.state.documents_by_id && $store.state.documents_by_id[row.document_id]')
                    //- .d-flex
                    //- span.d-block(v-for='col in $store.state.documents_by_id[row.document_id].config.cols' v-if='col.key == row.date.col_key' style='font-size: 12px; font-weight: 600') [{{ col.label }}]
                    strong(v-for='col in $store.state.documents_by_id[row.document_id].config.cols' v-if='col.key == row.date.col_key') {{ col.label }}
                    .d-flex.flex-wrap
                      span(v-if='row.date.date == date')
                        span(v-if='row.date.use_time == "Y"') {{row.date.time | time}}
                        span.mx-1.opacity-50(v-show='row.date.use_end_date == "Y"')
                          //- span(style='position: relative; top: -2px; right: 0px; font-size: 10px;') |
                          | →
                      span(v-else)
                        span.opacity-50 {{row.date.date | date}}
                        span.mx-1(v-show='row.date.use_end_date == "Y"') →
                          span(style='position: relative; top: -2px; right: 0px; font-size: 10px;') |
                          //- span(style='margin-left:-1px;') |
                      span
                        span(v-show='row.date.use_end_date == "Y" && row.date.date != row.date.date_end')
                          span(v-if='row.date.date == date')
                            span.opacity-50 {{row.date.date_end | date}}
                          span(v-else)
                            span {{row.date.date_end | date}}
                          //- span.ml-1(v-show='row.date.use_time == "Y"') {{row.date.time_end | time}}
                  small.d-block.opacity-50 {{row.customer_text}}
                  .text-right
                    small.d-block.opacity-50
                      template(v-if='$store.state.documents_by_id && $store.state.documents_by_id[row.document_id]') {{$store.state.documents_by_id[row.document_id].name}}
                  //- .d-flex.flex-wrap
                    span
                      span {{___.date | date}}
                      span.ml-1(v-show='___.use_time == "Y"') {{___.time | time}}
                    span.mx-1.opacity-50(v-show='___.use_end_date == "Y"') →
                    span(v-show='___.date != ___.end_date')
                      span {{___.end_date | date}}
                      span.ml-1(v-show='___.use_time == "Y"') {{___.end_time | time}}
                template(v-else-if='row.type == "record_log"')
                  span.note.d-block.bg-light.p-1.rounded.text-dark {{row.json.note}}
                  .d-flex.flex-wrap
                    span.m-0
                      strong(v-if='row.date.use_time == "Y"') {{row.date.time | time}}
                    //- span
                    //-   span.mx-1.opacity-50(v-show='row.date.use_end_date == "Y"') →
                    //- span.opacity-50
                    //-   span(v-show='row.date.date != row.date.date_end')
                    //-     span {{row.date.date_end | date}}
                    //-     //- span.ml-1(v-show='row.date.use_time == "Y"') {{row.date.time_end | time}}
                  small.d-block.opacity-50 {{row.customer_text}}
                  .text-right
                    small.d-block.opacity-50
                      template(v-if='$store.state.documents_by_id && $store.state.documents_by_id[row.document_id]') {{$store.state.documents_by_id[row.document_id].name}}

    modal(name='record' width='1130px' :height='`auto`' :scrollable='true' draggable='.header-draggable'
      @before-close='record_before_close'
      transition='none'
      overlay-transition='none'
    )
      //- :clickToClose='false'
      button.btn.btn-default.float-right.text-muted.rounded-0.bg-light(type='button' @click='$modal.hide("record")' style='font-size: 1.5rem'): b-icon-x
      //- div(style='height: 650px')
      div
        document-record(
          :property='property'
          :document='document'
          :document_record_id='document_record_id'
          @loaded='record_did_loaded'
          @updated='record_did_updated'
          @updated_note='record_note_did_updated'
          @closing='$modal.hide("record")'
        )
        //- :record_id='record_id'
        //- :tags='tags'
        //- @tag_updated='record_tag_did_updated'

</template>

<script>

import ExcelImport from '@/components/ExcelImport'
import DocumentRecord from '@/components/DocumentRecord'
import ContactSearchFilter from "@/components/ContactSearchFilter";

import { customAlphabet } from 'nanoid'
const nanoid = customAlphabet('123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz', 5)

import draggable from 'vuedraggable'
const xlsx = require('xlsx')
const moment = require('moment')

export default {
  name: 'index',
  props: ['document_id', 'document_name', 'saved_filter_id', 'document_record_id'],
  components: {
    ExcelImport, DocumentRecord, ContactSearchFilter,
    draggable,
  },
  computed: {
    session() {
      return this.$store.state.session
    },
    property() {
      return this.$store.state.property
    },
  },
  watch: {
    '$store.state.documents'() {
      this.load()
    },
    'document_id'() {
      this.rows_ready = false
      this.load()
    },
    'document_record_id'() {
      this.open_modal()
    },
  },
  mounted() {
    this.load()
    // setTimeout(() => {
    //   this.open_filter_modal()
    // }, 300);
  },
  filters: {
    first(e) {
      return e ? e[0] : ''
    },
  },
  data() {
    return {
      done: false,
      loading: false,
      adding: false,
      document: {},
      rows: [],
      rows_count: 0,
      rows_ready: false,
      rows_selected_count: 0,
      fills: Array.from('00000000000000000000000'),

      record_loaded: false,

      dropdown_group_record_active: false,

      filters: [],
      selected_filter: null,

      saved_filter: {},
      form_filter: {
        name: '',
        is_shared: 'N',
      },
      filter_changed: false,

      tags: [],
      tags_by_record_id: {},
      tags_count: null,

      min_height: '90vh',
      should_open_new_window: false,
      selected_all: false,

      sort: 'DESC',
      sortby: 'created_at',
      cols: [],
      cols_by_key: {},
      cols_by_key_loaded: false,

      tag_selected_action: '',

      fullpage_record_id: null,
      scroll_stop: false,

      cols_ready: false,
      cols_primary: [],

      groups: [],
      groups_by_format: {},

      // document_record_id: null,

      drag: false,

      drag_options: {
        animation: 200,
        ghostClass: 'ghost',
      },
      cols_ordered: [],

      new_filter_name: '',
      current_filter: {},

      has_document_id: [],

      checked_projects: [],
      project_filter_input: '',
      project_filter_search_input: {},

      dates: [],
      date_indexed: {},
      from: moment().format('YYYY-MM-DD'),
      to: moment().add('22', 'days').format('YYYY-MM-DD'),
      today: moment().format('YYYY-MM-DD'),
      dates_dow: {},
    }
  },
  methods: {

    async open_modal() {
      if (this.document_record_id) {
        if (!this.document || !this.document.id) {
          try {
            this.loading = true
            const r = await this.$http.get(`/v1/property/${this.property.id}/views/documents/records/row/${this.document_record_id}/find_document_id`)
            const document_id = r?.data?.row?.document_id
            if (!document_id) throw new Error('해당 내역의 프로젝트를 찾을 수 없습니다.')
            this.document = this.$store.state.documents_by_id[document_id]
          } catch (error) {
            this.$modal.show('dialog', {
              title: '알림',
              text: error.message,
            })
          }
        }
        this.$modal.show('record')
      } else {
        this.$modal.hide('record')
      }
    },
    record_did_loaded() {
      this.record_loaded = true
      // setTimeout(() => { this.loading = false }, 300)
    },
    record_did_updated() {
      console.log('reload from record_did_updated')
      this.load()
    },
    record_note_did_updated() {
      console.log('reload from record_note_did_updated')
      this.load()
    },
    record_before_close(event) {
      // event.cancel()
      if (this.document_record_id) {
        // this.document_record_id = null
        // this.open_modal()
        this.$router.push({
          name: 'calendar.view',
        })
        setTimeout(() => {
          this.update_title()
        }, 100);
      }
    },
    open_record_new(row) {
      const r = this.$router.resolve({
        name: 'calendar.view.record',
        params: {
          document_record_id: row.record_id,
        }
      })
      window.open(r.href, '_blank')
      return false
    },
    open_record(row) {
      this.document = this.$store.state.documents_by_id[row.document_id]
      this.$router.push({
        name: 'calendar.view.record',
        params: {
          document_record_id: row.record_id,
        }
      })
    },
    select_all() {

    },
    update_title() {
      setTimeout(() => {
        const text = []
        text.push(`달력`)
        if (!this.document_record_id) {
          document.title = text.join(' ')
        }
      }, 100);
    },
    async load() {
      if (!this.$store.state.documents || this.$store.state.documents.length == 0) return
      if (!this.$store.state.property) return

      try {

        console.log('groups init', this.groups)
        if (this.groups.length === 0) {
          this.groups = [
            // {
            //   format: '@고객아이디',
            // },
            {
              format: '@고객이름',
            },
            // {
            //   format: '@고객연락처',
            // },
            // {
            //   format: '@고객이메일',
            // },
          ]
        }
        this.groups_by_format = Object.assign({}, ...this.groups.map(e => {
          return { [e.format]: e }
        }))

        this.loading = true
        const r = await this.$http.get(`/v1/property/${this.property.id}/views/documents/calendars/records`, {
          params: {
            from: this.from,
            to: this.to,
            // groups: JSON.stringify(this.groups),
            // filters: JSON.stringify(this.filters),
            // sort: this.sort,
            // sortby: this.sortby,
          }
        })
        const m = r?.data?.message || ''
        if (m != 'ok') {
          throw new Error('불러오기 실패: '+ r?.data?.message)
        }
        this.dates = r.data.dates
        const dates_dow = {}
        for (const d of this.dates) {
          const dow = moment(d, 'YYYY-MM-DD').day()
          if (dow == 0) dates_dow[d] = 'day-sun'
          else if (dow == 6) dates_dow[d] = 'day-sat'
          else dates_dow[d] = 'day-week'
        }
        this.dates_dow = dates_dow
        this.date_indexed = r.data.date_indexed
        // this.record_indexed_by_id = r.data.record_indexed_by_id
        // this.record_log_indexed_by_id = r.data.record_log_indexed_by_id
        this.rows_count = r.data.rows_count
        // this.rows_selected_count = 0
        this.rows_ready = true

        this.cols_ready = true
        const cols_by_key = {}

        this.saving_text = '저장'
        this.done = true

        // this.document_record_id = this.document_record_id
        this.open_modal()
        // this.open_filter_modal()
        this.update_title()
      } catch (error) {
        console.log(error)
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.loading = false
    },
  },
}
</script>
